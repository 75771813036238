import {
  ChakraProvider,
} from '@chakra-ui/provider';
import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';
import React from 'react';
import toast, {
  Toaster
} from 'react-hot-toast';
import { RecoilRoot } from 'recoil';
import { ToasterAlert } from '@/components/Alert/ToasterAlert/ToasterAlert';
import { InternalServerError } from '@/errors/httpErrors';
import { theme } from '@/theme';
import { AppProps } from '@/types/nextjs';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      cacheTime: 0,
      staleTime: 0,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      onError: (error) => {
        console.error(error);
        if (error instanceof InternalServerError) {
          return toast.error(error.message);
        }
      }
    },
    mutations: {
      retry: false,
      cacheTime: 0,
      // NOTE: mutateAsyncでは機能しない
      onError: (error) => {
        console.error(error);
        if (error instanceof InternalServerError) {
          toast.error(error.message);
        }
      }
    }
  }
});

const MyApp = ({ Component, pageProps, router: _router }: AppProps) => {
  const getLayout = Component.getLayout || ((page) => page);

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <RecoilRoot>
          {getLayout(
            <Component {...pageProps}/>
          )}
          <Toaster>
            {(t) => (
              <ToasterAlert
                t={t}
              />
            )}
          </Toaster>
        </RecoilRoot>
      </ChakraProvider>
    </QueryClientProvider>
  );
};

export default MyApp;
