import {
  AlertDescription,
  AlertIcon,
  AlertStatus
} from '@chakra-ui/alert';
import {
  Alert,
  Box,
  CloseButton,
} from '@chakra-ui/react';
import toast, {
  resolveValue,
  Toast
} from 'react-hot-toast';

type ToasterAlertProps = {
  t: Toast;
  closable?: boolean;
};

export const ToasterAlert = ({ t }: ToasterAlertProps) => {
  let status: AlertStatus = 'info';
  if (t.type === 'success') {
    status = 'success';
  } else if (t.type === 'error') {
    status = 'error';
  }

  return (
    <>
      <Alert
        w={{ base: '100%', sm: '500px' }}
        status={status}
        borderRadius={7}
      >
        <AlertIcon/>
        <Box
          w={'full'}
        >
          <AlertDescription>
            {resolveValue(t.message, t)}
          </AlertDescription>
        </Box>
        <CloseButton
          alignSelf="flex-start"
          position="relative"
          right={-1}
          size={'sm'}
          onClick={() => toast.dismiss(t.id)}
        />
      </Alert>
    </>
  );
};
